/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var hideModal = function() {
    $('body').removeClass("modal-open");
    var $modal = $('.modal');
    $('html, body').animate({
      scrollTop: $(".who-we-are-post").offset().top
    }, 0);
    $('.mobile-nav-button').css("display", "");
    $modal.fadeOut(250, function() {
      $modal.remove();
    });
  };

  var hideModalInfo = function(cb) {
    var $modalInfo = $('.modal-info');
    $modalInfo.fadeOut(250, function() {
      $modalInfo.remove();
      cb();
    });
  };

  var hideShareModal = function(e) {
    if (e.target.className !== "share-icon") {
      var $modal = $('.share-modal');
      $modal.remove();
      $(document).off();
    }
  };

  var findCurrent = function() {
    var $people = $('.modal-info-wrapper .modal-info');
    var currentIdx;
    $people.each(function(idx){
      if ($($people[idx]).data('active')) {
        currentIdx = idx;
      }
    });
    return currentIdx;
  };

  var recenterModal = function(e) {
    var currentIdx = findCurrent();
    var width = $($('.modal-info')[currentIdx]).outerWidth();
    $('.modal-info-wrapper').animate({
      scrollLeft: width * currentIdx
    }, 0);
  };

  var decreaseModal = function() {
    var $previousButton = $('.previous-button');
    var $nextButton = $('.next-button');
    $previousButton.off();
    $nextButton.off();

    var $people = $('.modal-info-wrapper .modal-info');
    var currentIdx = findCurrent();
    var nextIdx = currentIdx - 1;

    // CHANGE LINE BELOW FOR WHEN RICHARD RETURNS TO CAROUSEL

    if (nextIdx < 0) {
      nextIdx = $people.length - 1;
    }
    $($people[currentIdx]).removeData('active');
    $($people[nextIdx]).data('active', true);

    var height = $('.modal').height() * 0.75;
    var $dummy = $('<div class="modal-info-wrapper modal-dummy">');
    $dummy.css('height', height);
    $dummy.append($($people[currentIdx]).clone());
    $('.modal').append($dummy);

    var width = $($('.modal-info')[0]).outerWidth();
    if (nextIdx === $people.length - 1) {
      $('.modal-info-wrapper').scrollLeft(width * $people.length);
    } else {
      $('.modal-info-wrapper').scrollLeft(width * nextIdx);
    }

    $dummy.fadeOut(250, function(){
      $dummy.remove();
      $previousButton.on('click', decreaseModal);
      $nextButton.on('click', increaseModal);
    });
  };

  var swipeDecrease = function() {
    var $people = $('.modal-info-wrapper .modal-info');
    var currentIdx = findCurrent();

    // CHANGE LINE BELOW FOR WHEN RICHARD RETURNS TO CAROUSEL

    if (currentIdx === 0) {
      var width = $($('.modal-info')[currentIdx]).outerWidth();
      $('.modal-info-wrapper').animate({
        scrollLeft: width * currentIdx
      }, 250);
    } else {
      var nextIdx = currentIdx - 1;
      if (nextIdx < 0) {
        nextIdx = $people.length - 1;
      }
      var width = $($('.modal-info')[0]).outerWidth();
      if (nextIdx !== $people.length - 1) {
        $($people[currentIdx]).removeData('active');
        $($people[nextIdx]).data('active', true);
        $('.modal-info-wrapper').animate({
          scrollLeft: width * nextIdx
        }, 250);
      }
    }
  };

  var increaseModal = function() {
    var $previousButton = $('.previous-button');
    var $nextButton = $('.next-button');
    $previousButton.off();
    $nextButton.off();

    var $people = $('.modal-info-wrapper .modal-info');
    var currentIdx = findCurrent();
    var nextIdx = currentIdx + 1;
    if (nextIdx >= $people.length) {

      // CHANGE LINE BELOW FOR WHEN RICHARD RETURNS TO CAROUSEL

      nextIdx = 0;
    }
    $($people[currentIdx]).removeData('active');
    $($people[nextIdx]).data('active', true);
    var height = $('.modal').height() * 0.75;
    var $dummy = $('<div class="modal-info-wrapper modal-dummy">');
    $dummy.css('height', height);
    $dummy.append($($people[currentIdx]).clone());
    $('.modal').append($dummy);

    if (nextIdx !== 0) {
      var width = $($('.modal-info')[0]).outerWidth();
      $($('.modal-info-wrapper')[0]).scrollLeft(width * nextIdx);
    } else {
      $($('.modal-info-wrapper')[0]).scrollLeft(0);
    }

    $dummy.fadeOut(250, function(){
      $dummy.remove();
      $previousButton.on('click', decreaseModal);
      $nextButton.on('click', increaseModal);
    });
  };

  var swipeIncrease = function() {
    var $people = $('.modal-info-wrapper .modal-info');
    var currentIdx = findCurrent();
    if (currentIdx === $people.length - 1) {
      var width = $($('.modal-info')[currentIdx]).outerWidth();
      $('.modal-info-wrapper').animate({
        scrollLeft: width * currentIdx
      }, 250);
    } else {
      var nextIdx = currentIdx + 1;
      if (nextIdx >= $people.length) {
        nextIdx = 0;
      }
      if (nextIdx !== 0) {
        $($people[currentIdx]).removeData('active');
        $($people[nextIdx]).data('active', true);
        var width = $($('.modal-info')[0]).outerWidth();
        $('.modal-info-wrapper').animate({
          scrollLeft: width * nextIdx
        }, 250);
      }
    }
  };

  var constructCloseModal = function() {
    $mobileNavButton = $('<div class="mobile-nav-button">');
    $wrapper = $('<div class="wrapper">');
    $topBar = $('<div class="line top transformed">');
    $botBar = $('<div class="line bot transformed">');
    $wrapper.append($topBar);
    $wrapper.append($botBar);
    $mobileNavButton.append($wrapper);
    return $mobileNavButton;
  };

  var constructModal = function($modalInfo) {
    var $modal = $('<div class="modal">');
    var $modalBackground = $('<div class=modal-background>');
    var $closeIcon = constructCloseModal();
    $closeIcon.addClass("close-face-modal");
    var $previousButton = $('<div class="previous-button"></div>');
    $previousButton.on('click', decreaseModal);
    var $nextButton = $('<div class="next-button"></div>');
    $nextButton.on('click', increaseModal);
    $closeIcon.on('click', hideModal);
    $modal.append($closeIcon);
    var $modalInfoWrapper = $('<div class="modal-info-wrapper" id="modal-info-wrapper">');
    $modalInfoWrapper.append($modalInfo);
    $modal.append($modalInfoWrapper);
    $modal.append($modalBackground);
    $modal.append($previousButton);
    $modal.append($nextButton);
    return $modal;
  };

  var constructPerson = function($infoBox, bioText, personIdx) {
    var $bio = $('<div class="bio">');
    $bio.append(bioText);
    var $modalInfo = $('<div class=modal-info>');
    $modalInfo.data('idx', personIdx);
    $modalInfo.append($infoBox);
    $modalInfo.append($bio);
    return $modalInfo;
  };

  var showInfoModal = function(e) {
    var $people = $('.who-we-are-info');
    var $peopleInfo = [];
    var currentIdx;
    $people.each(function(idx){
      var $person = $($people[idx]).clone();
      var bio = $($people[idx]).data("bio");
      var $newPerson = constructPerson($person, bio, idx);
      if ($people[idx] === e.currentTarget) {
        currentIdx = idx;
        $newPerson.data('active', true);
      }
      $peopleInfo.push($newPerson);
    });
    var $modal = constructModal($peopleInfo);
    $('.bar').css('display', 'none');
    $('body').append($modal);
    $modal.hide().fadeIn(250, function(){
      $('body').addClass("modal-open");
      $('.bar').css('display', '');
    });
    $('.mobile-nav-button').css("display", "none");
    var modal = document.getElementById('modal-info-wrapper');
    var mc = new Hammer(modal);
    mc.get('swipe').set({preventDefault: true});
    mc.on('swipeleft', swipeIncrease);
    mc.on('swiperight', swipeDecrease);
    $destination = $($('.modal-info')[currentIdx]);
    var offset = ($('.modal-info-wrapper').offset().left);
    $('.modal-info-wrapper').animate({
      scrollLeft: $destination.position().left - offset
    }, 0);
    $(window).on('resize', recenterModal);
  };

  var showShareModal = function(e) {
    $('.share-modal').remove();
    var $footer = $(e.currentTarget).parent();
    var $shareModal = $('<div class="share-modal"></div>');
    var permalink = $(e.currentTarget).data("url");
    var title = $(e.currentTarget).data("title");
    var $twitterScript = $('<script src="http://platform.twitter.com/widgets.js" type="text/javascript"></script>');
    var $twitterShare =
      $('<a class="twitter-share-button" href="http://twitter.com/share" data-url="http:'+permalink+'" data-text="'+title+'"></a>');
    twttr.events.bind('tweet', function() {
      s.prop33 = "Twitter";
      s.eVar33 = "Twitter";
      s.prop34 = "Tweet";
      s.eVar34 = "Tweet";
      s.linkTrackEvents='event17';
      s.events='event17';
      s.tl(window, 'o', 'Event Tracking');
    });
    var $facebookShare = $('<div class="fb-like" data-href="http:'+permalink+'" data-width="180" data-layout="button_count" data-action="like" data-show-faces="false" data-share="false"></div>');
    var $facebookScript = $('<div id="fb-root"></div><script>FB = null; (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; js = d.createElement(s); js.id = id; js.src = "//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.5"; fjs.parentNode.insertBefore(js, fjs);}(document, "script", "facebook-jssdk"));</script>');
    var monitor = setInterval(function(){
    var elem = document.activeElement;
    if(elem && elem.tagName == 'IFRAME' && elem.title === "fb:like Facebook Social Plugin"){
      clearInterval(monitor);
      s.prop33 = "Facebook";
      s.eVar33 = "Facebook";
      s.prop34 = "Fshare";
      s.eVar34 = "Fshare";
      s.tl(window, 'o', 'Event Tracking');
      }
    }, 100);
    $shareModal.append($twitterScript);
    $shareModal.append($twitterShare);
    $shareModal.append($facebookScript);
    $shareModal.append($facebookShare);
    $footer.append($shareModal);
    $(document).on('click touchstart', hideShareModal);
  };

  var techScroll = function(e) {
      e.preventDefault();
    $('html, body').animate({
      scrollTop: $("#technology").offset().top
    }, 1000).promise().then(function() {
      s.pageName = "series: silicon-valley: inside: pied piper technology";
      s.t();
    });
  };
  var whoWeAreScroll = function(e) {
      e.preventDefault();
    $('html, body').animate({
      scrollTop: $(".who-we-are-post").offset().top
    }, 1000).promise().then(function() {
      s.pageName = "series: silicon-valley: inside: pied piper who we are";
      s.t();
    });
  };

  var removeNavModal = function() {
    var $modal = $('.nav-modal');
    $modal.fadeOut(100, function() {
      var $button = $('.mobile-nav-button');
      $button.removeClass('transformed');
      $modal.remove();
      $('body').removeClass("modal-open");
    });
  };

  var showMobileNavModal = function() {
    var $button = $('.mobile-nav-button');

    if ($button.hasClass('transformed')) {
      removeNavModal();
    } else {
      $button.addClass('transformed');
      var url = $('.blog-header').data('url');
      var $modal = $('<div class=nav-modal>');
      var $ul = $('<ul class="mobile-nav">');
      var $home;
      var $technology;
      var $whoWeAre;

      if (url) {
        $home = $('<li><a href="'+url+'/#home">Home</a></li>');
        $technology = $('<li><a href="'+url+'/#technology">Technology</a></li>');
        $whoWeAre = $('<li><a href="'+url+'/#whoweare">Who We Are</a></li>');
      } else {
        $home = $('<li>Home</li>');
        $home.on('click', function() {
          removeNavModal();
          $('html, body').animate({
            scrollTop: 0
          }, 0);
        });

        $technology = $('<li>Technology</li>');
        $technology.on('click', function() {
          removeNavModal();
          $('html, body').animate({
            scrollTop: $("#technology").offset().top
          }, 1000).promise().then(function() {
            s.pageName = "series: silicon-valley: inside: pied piper technology";
            s.t();
          });
        });

        $whoWeAre = $('<li>Who We Are</li>');
        $whoWeAre.on('click', function() {
          removeNavModal();
          $('html, body').animate({
            scrollTop: $(".who-we-are-post").offset().top
          }, 1000).promise().then(function() {
            s.pageName = "series: silicon-valley: inside: pied piper who we are";
            s.t();
          });
        });
        $('body').addClass("modal-open");
      }
      $ul.append($home);
      $ul.append($technology);
      $ul.append($whoWeAre);
      $ul.append($('<li><a href="'+url+'/blog">Blog</a></li>'));

      $modal.append();
      $modal.append($ul);
      $('body').append($modal);
      $modal.hide().fadeIn(100);
    }
  };

  if ($('.technology').length) {
    var technology = [$('.technology').offset().top, $('.technology').offset().top + $('.technology').outerHeight(true)];
  }
  if ($('.who-we-are-post').length) {
    var whoWeAre = [$('.who-we-are-post').offset().top, $('.who-we-are-post').offset().top + $('.who-we-are-post').outerHeight(true)];
  }
  var currentPane = "home";
  var scrollTracker = function() {
    var scrollPos = $(document).scrollTop();
    if (scrollPos > technology[0] && scrollPos < technology[1]) {
      setTimeout(function() {
        var scrollPos = $(document).scrollTop();
        if (scrollPos > technology[0] && scrollPos < technology[1]) {
          if (currentPane === "technology") {
            return;
          }
          s.pageName = "series: silicon-valley: inside: pied piper technology";
          s.t();
          currentPane = "technology";
        }
      }, 500);
    } else if (scrollPos > whoWeAre[0] && scrollPos < whoWeAre[1]) {
      setTimeout(function() {
        var scrollPos = $(document).scrollTop();
        if (scrollPos > whoWeAre[0] && scrollPos < whoWeAre[1]) {
          if (currentPane === "whoWeAre") {
            return;
          }
          s.pageName = "series: silicon-valley: inside: pied piper who we are";
          s.t();
          currentPane = "whoWeAre";
        }
      }, 500);
    } else if (scrollPos < technology[0]){
      setTimeout(function() {
        var scrollPos = $(document).scrollTop();
        if (scrollPos < technology[0]) {
          if (currentPane === "home") {
            return;
          }
          s.pageName = "series: silicon-valley: inside: pied piper";
          s.t();
          currentPane = "home";
        }
      }, 500);
    }
  };

  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.share-icon').on('click', showShareModal);
        $('.mobile-nav-button').on('click', showMobileNavModal);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.who-we-are-info').on('click', showInfoModal);
        $whoWeAre = $('.who-we-are-info');
        $greyed = $('.greyed');
        $('.greyed').off('click');
        $('.tech-anchor').on('click', techScroll);
        $('.who-we-are-anchor').on('click', whoWeAreScroll);
        $(window).on('scroll', scrollTracker);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'blog': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
